<template>

<h1>Il semble que vous soyez perdu, ici c'est le nullarbor....</h1>

</template>
<script lang="ts">



</script>
<style scoped lang="scss">
@import "../scss/variables.scss";


</style>